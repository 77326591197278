/*////////// STYLE HEADER //////////*/
body{
    overflow-x: hidden;

}

@font-face {
    font-family: 'd-din';
    src: local('d-din'), url(../fonts/d-din.woff) format('woff');
    font-stretch: normal;
}
@font-face {
    font-family: 'd-din';
    src: local('d-din'), url(../fonts/d-dincondensed.woff) format('woff');
    font-stretch: condensed;
}

.headerContainer{
    height: 2%;
    padding: 1%;
    font-family: "d-din";
    font-stretch: condensed;
    font-weight: 100 ;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.burgerMenuContainer,.burgerMenuNavContainer{
    display: none;
}

/* Logo element */
.logoContainer{
    order: 1;
    width: 20vw;
    position: relative;
}

.logoPacman{
    position: absolute;
    width: 5.5vw;
    height: 5.5vw;
    background-color: #33495E;
    border-radius: 50%;
    top: 0;
    left: 17.5%;
    clip-path: polygon(0 0, 100% 0, 100% 13%, 52% 52%, 100% 87%, 100% 100%, 0 100%, 0 0);
    transition: clip-path .2s ease-in;
}

#homeLogo:hover>.logoPacman{
    clip-path: polygon(0 0, 100% 0, 100% 52%, 52% 52%, 100% 52%, 100% 100%, 0 100%, 0 0);

}

.headerRightSide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    order: 2;
}

/* NavMenu element */
.navMenuContainer{
    justify-self: flex-end;
    padding-top: 5%;
    width: 400px;
    height: 10%;
    order: 2;
    resize: none;
    min-width: 318px;
}

.navElements{
    text-decoration: none;
    color: black;
    font-size: 2.1em ;
    padding-bottom: 2px;
    border-bottom: 4px solid #293947;
    transition: all 0.3s cubic-bezier( 0.94, 0.38, .48, 1.2 );
    clip-path: polygon(-100% -20%, 200% -20%, 200% 100%, 20% 100%,20% 85%, 15% 85%, 15% 100%, -100% 100%, -100% -20%);
}

.navElements:hover{
    /* animation: underlineSlide 0.3s cubic-bezier( 0.94, 0.38, .48, 1.2 )   forwards; */
    clip-path: polygon(-100% -20%, 200% -20%, 200% 100%, 80% 100%,80% 85%, 75% 85%, 75% 100%, -100% 100%, -100% -20%);

}

.navElements:nth-child(2){
    text-align: left;
    margin-left: 6%;
}


/* Time element */
.timeContainer{
    order:3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-self: flex-end;
}

.timeItem{
    text-align: right;
}

#timeClock{
    font-size: 4.4em; 
    align-self: flex-start;
}

#timeLocation{
    font-size: 2.11em;
    text-align: center;
}

#timeLanguage{
    padding-top: 5%;
    position: relative;

}

.languageItem{
    cursor: pointer;
}

#selectedLanguageBackground{
    position: absolute;
    
    height: 70%;
    transition: all .3s cubic-bezier(.17,.67,.55,1.95);
    background-color: #FACBCC;
    z-index: -1;
}

#en:hover ~ div.frSelected {
    top: 30%;
    left:41%;
    width: 60%;
}

#fr:hover ~ div.enSelected {
    top: 30%;
    left:-2%;
    width: 55%;
}


.frSelected{
    top: 30%;
    left:53%;
    width: 48%;
}

.enSelected{
    top: 30%;
    left:-2%;
    width: 43%;
}

/*////////// GENERAL HIGHLIGHT STYLES //////////*/
.highlight{
    padding: 0 2px;
}
.highlightYellow{
    background-color: #EDBD21;
    color: black;
}

.highlightPink{
    background-color: #FACBCC;
    color: black;
}

.highlightBlue{
    background-color: #33688C;
}

.highlightGrey{
    background-color: #CCCCCC;
    color: black;
}

/*////////// HOME CONTENT //////////*/
#contentContainer{
    font-family: "d-din";
    color: black;
    font-size: 1em;
}

/*Main skills element*/
.mainSkills{
    font-stretch: condensed;
    margin-top: 3.5%;
}

#skillList{
    list-style-type: none;
    display:inline-block;
}

.skillsListElement{
    width: auto;
    
}

#skillList > li:nth-child(2){
    margin-top: 5%;
}

.skillsListElement:hover >a.skillLink> .boxHoverBehind{
    width: 105%;
}

.skillLink{
    position: relative;
    text-decoration: none;
    color: black;
    font-size: 2em;
    font-weight: 350 ;
}

.boxHoverBehind{
    width: 0%;
    height: 105%;
    z-index: -1;
    position: absolute;
    top: -8%;
    left: -2.5%;
    transition: width .2s ease-in;
}

#skillProgrammer>a.skillLink>.boxHoverBehind{
    background-color: #EDBD21;
}

#skillDesigner>a.skillLink>.boxHoverBehind{
    background-color:#FACBCC;
}

/*Resume element*/
#homeResumeContainer{
    background-color: #33495E;
    position: relative;
    color: white;
    margin-top: 5%;
    padding:1em;
    padding-left: 3.5em;
    margin-bottom: 100px;
}

#resumeContainer{
    font-size: 1.7em;
    font-weight: lighter;
    width: 75%;
}

#borderResume{
    position: absolute;
    background-color: white;
    width: 75%;
    height: 8px;
    top:0;
}

.resumeElement{
    margin-left: .5em;
}

#resumeP1{
    width: 69%;
}

#resumeP2{
    width: 90%;
}


/*////////// PROJECTS LIST PAGE //////////*/
.listContainer{
    margin-left: 5%;
    margin-top: 2%;
    width: 100%;
    font-size: 1.15em;
    font-stretch: condensed;

}

.listTitleContainer{
    width: 40%;
    border-bottom: 4px solid #293947;
    padding-left: 1%;
    font-size: 2em;
}

.listField{
    position: relative;
    margin-left: 5%;
    margin-top: 2%;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
}

.listFieldTitle{
    font-size: 1.8em;
}

.listTitle>p{
    text-decoration: none;
    color: rgb(58, 58, 58);
    opacity: 0.4;
    margin: 0;
}

.listTitle>.activeListTitle{
    color: black;
    opacity: 1;
}

.listTitle>.inactiveListTitle{
    transition: opacity 0.3s ease-out;
}

.listTitle>.inactiveListTitle:hover{
    opacity: .8;
}

.listItemTitle>.boxHoverBehind{
    background-color: #EDBD21;
    left:-5px;
}

.listItemTitle:hover>.boxHoverBehind{
    width: calc(100% + 8px);
    
}

.listItemTitle:hover>.listItemBall{
    background-color: #33495E;
}

.listItem{
    margin-left: 5%;
    margin-top: 40px;
    transform-origin: top left;
}

.listField>.listItem:nth-of-type(1){
    margin-top: 25px;
}

.listItemTitle{
    font-size: 1.7em;
    position: relative;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: black;
}

.listItemTitle>p{
    margin: 0;
    color: #777777;
}

.listItemBall{
    position: absolute;
    left: -40px;
    top: 35%;

    background-color: #EDBD21;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.listItemSoftware{
    width: 150px;
    position: absolute;
    left: 100%;
    transform: translateX(8px);
    top: 25%;
    font-size: .6em;
    font-weight: 400;
}

.listItemDate{
    position: absolute;
    left: 0;
    top:100%;
    width: 300%;
    font-size: .8em;
    color: #4D4D4D;
}

.listSeparator{
    position: absolute;
    left:-11%;
    top: 102%;
    height: 2px;
    width: 99vw;
    background-color:#33495E ;
}
.listField:last-child>.listSeparator{
    display: none;
}

/*////////// PROJECT PROFILE PAGE //////////*/
.projectContentContainer{
    margin-left: 5%;
    margin-top: 2%;
}

.projectContentContainer>div{
    margin-top: 2%;
}

/*TITLE*/
.projectTitleContainer{
    position: relative;
    border-bottom: 4px solid #293947;
    padding-left: 3.5%;
    font-size: 2em;
    width: 50%
}

.backToList{
    position: absolute;
    top: 25%;
    left: -1.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 6%;
    cursor: pointer;
    transition: all .2s ease-in;
    text-decoration: none;

}

.backToList:hover{
    width: 7%;
    left: -2.5%;
}

.backToList:hover>.ballBack:nth-child(1){
    transform: scale(1.5);
}
.backToList:hover>.ballBack:nth-child(2){
    transform: scale(1.25);
}

.ballBack{
    width: .8vw;
    height: .8vw;
    border-radius: 50%;
    background-color: #EDBD21;
    transition: all .2s cubic-bezier(.5,0,.32,1.72);
    z-index: -1;
}

.ballBackPassive>.ballBack{
    animation: passiveBallBack 5s infinite cubic-bezier(.5,0,.32,1.72);
}

.ballBackActive{
    animation: none;
}

.ballBackPassive>.ballBack:nth-child(3){
    animation-delay: 0s;
}
.ballBackPassive>.ballBack:nth-child(2){
    animation-delay: .3s;
}
.ballBackPassive>.ballBack:nth-child(1){
    animation-delay: .6s;
}

@keyframes passiveBallBack{
    from{
        transform: scale(1);
    }
    15%{
        transform: scale(1);
    }
    25%{
        transform: scale(1.5);
    }
    35%{
        transform: scale(1);

    }
    to{
        transform: scale(1);
    }
}



/*INFOS*/
.projectInfosContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 400px;
    min-width: 400px;
}

.projectInfoTitle{
    color: black;
}

.projectInfo{
    color: #5f5f5f;
}

.projectCollabInfos{
    background-color: #D9DBDE;
    padding: 5px;
}

.projectCollabInfos>p{
    margin: 0;
    padding: 0;
}

/*PREVIEW*/
.projectPreviewsContainer{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    max-width: 80%;
}

.projectPreviewsContainer>img{
    margin-right: 3%;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 300px;
    flex: 1;
    cursor: pointer;
    transition: filter .2s ease-out;
}

.projectPreviewImg:hover{
    filter: brightness(80%);
}

/*gallery*/
/*container*/
.galleryContainer{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
}
.galleryContainerBackground{
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: .6;
    width: 100vw;
    height: 100%;
    z-index: -1;
}

/*exit*/
.exitGallery{
    position: absolute;
    top: 5%;
    right: 0%;
    width: 30px;
    height: 30px;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    background-color: white;
    cursor: pointer;
    transform-origin: center;
    transition: all .3s ease-out;
}

.exitGallery:hover{
    clip-path: polygon(26% 6%, 7% 25%, 30% 50%, 6% 75%, 26% 95%, 50% 70%, 74% 94%, 94% 71%, 70% 50%, 93% 24%, 73% 7%, 50% 30%);
}

/*Content*/
.galleryContentContainer{
    height: 100vh;
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
}

/*expanded*/
.galleryExpandedImg{
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.gallerExpandedImgContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 90%;
}

#galleryExpandedImgContent{
    height: auto;
    width: 60vw;
}

.galleryExpandedNavBox{
    width: 40px;
    height: 40px;
    font-size: 3em;
    border-radius: 50%;
    background-color: #EDBD21;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#galleryNavLeft:hover>.navSymbol{
    margin-right: 5px;
}
#galleryNavRight:hover>.navSymbol{
    margin-left: 5px;
}

#galleryNavLeft{
    margin-right: 10px;
}
#galleryNavRight{
    margin-left: 10px;
}
/*navigation*/
.navSymbol{
    width: 38%;
    height: 38%;
    border-radius: 50%;
    background-color: black;
    transition: margin .2s ease-out;
}

#navRightSymbol{
    clip-path: polygon(20% 0%, 40% 0%, 100% 50%, 40% 100%, 20% 100%, 80% 50%, 20% 0);
}
#navLeftSymbol{
    clip-path: polygon(80% 0%, 60% 0%, 0% 50%, 60% 100%, 80% 100%, 20% 50%, 80% 0);
}

/*row*/
.galleryImgRowContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    margin-top: -15px;
}
.galleryImgRowItem{
    width: 30%;
}
.galleryImgRowItem>img{
    width: 100%;
    filter: brightness(.9);
    cursor: pointer;
}
.galleryImgRowItem>img:hover{
    filter: brightness(1);
}



/*TEXT*/
.projectTextContainer{
    position: relative;
}

.projectTextSeparator{
    position: absolute;
    left: 0;
    height: 3px;
    width: 40%;
    background-color: #33495E;
    margin-left: -5.5%;
}

#projectTextContentContainer{
    padding-top: 1%;
    font-size: 1.25em;
    font-weight: 100;
    max-width: 80%;
    text-align: justify;
}
/*BOTTOM PART*/
.projectBottomContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 90%;
    margin-top: 4% !important;
    margin-bottom: 4% ;
}

/* VIDEO */
.projectVideoContainer{
    width: 35%;
    min-width: 500px;
    margin-right: 100px;

}


/*LINKS*/
.projectLinksBoxContainer{
    display: inline-block;
}

.projectLinksFlexContainer{
    display: flex;
    flex-direction: column;
}

.linkItem{
    text-decoration: none;
    color: black;
    font-stretch: condensed;
    font-weight: 400;
    position: relative;
    font-size: 1.2em;
    text-decoration: underline;
}

.linkItem:nth-child(2){
    margin-top: 10px;
}

.linkItemBall{
    position: absolute;
    left: -18%;
    top: 30%;
    display: inline-block;
    background-color: #EDBD21;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.linkItem:hover>.linkItemBall{
    background-color: #33495E;
} 

/*////////// MY WORK //////////*/
.myWorkContainer{
    margin-left: 5%;
    margin-top: 2%;
    font-family: "d-din";
}

/*TITLE*/
.myWorkTitleContainer{
    border-bottom: 4px solid #293947;
    font-size: 2em;
    width: 50%;
    padding-left: 1%;
    font-stretch: condensed;

}

/*CONTENT*/
.myWorkContent{
    margin-top: 3em;
    display: flex;
    flex-flow: row wrap;
    max-width: 85%;

}

/*ITEM*/
.myWorkItem{
    width: 300px;
    /* flex-grow: 1; */
    color: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 30px;
    text-decoration: none;
    margin-right: 20px;
}
/*item title*/
.myWorkItemTitleContainer{
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: #33495E;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    text-align: center;
}

/*item preview*/
.myWorkItemPreviewContainer{
    position: relative;
    align-self: center;
    width: 90%;
}

.myWorkItemPreview{
    width: 100%;
    transition: filter .1s ease-in-out;
}

.myWorkItemPreviewInfos{
    position: absolute;
    top: 10%;
    left: 5%;
    opacity: 0;
    transition: opacity .1s ease-in-out;
    font-stretch: condensed;
}

.myWorkItemPreviewDate{
    margin-top: 5%;
}

.myWorkItem:hover>.myWorkItemPreviewContainer>.myWorkItemPreviewInfos{
    opacity: 1;
}
.myWorkItem:hover>.myWorkItemPreviewContainer>.myWorkItemPreview{
    filter: brightness(60%);
}


/*////////// CONTACT PAGE //////////*/
.contactContainer{
    margin-top: 2%;
    font-family: "d-din";
    height: 64vh;
}

/*TITLE*/
.contactTitleContainer{
    border-bottom: 4px solid #293947;
    font-size: 2em;
    margin-left: 5%;
    width: 50%;
    padding-left: 1%;
    font-stretch: condensed;

}

/*EMAIL*/
.contactEmailContainer{
    margin-top: 50px;
    width: 100vw;
    background-color: #33495E;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    display: flex;
    align-items: center;
}

/*email*/
.contactEmailContent{
    margin-left: 8%;
    color: white;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    max-width: 80%;
}

.contactEmail,.contactEmailSideBalls{
    margin-right: 10px;
}

.contactEmail>a{
    color: white;
    text-decoration: none;
    font-size: 3em;
    font-weight: 100;
    font-stretch: condensed;
    position: relative;
}
.contactEmail>a:after{
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 0%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
.contactEmail>a:hover:after { 
    width: 100%; 
    left: 0; 
}

/*side*/
.contactEmailSide{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contactEmailSideBalls{
    display: flex;
    flex-direction: row;
    width: 140px;
    justify-content: space-between;
}

.contactEmailBall{
    background-color: #EDBD21;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    animation: animBallContact infinite 5s cubic-bezier(0,.42,.78,1.39);
}

.contactEmailBall:nth-of-type(1){
    animation-delay: 0s;
}
.contactEmailBall:nth-of-type(2){
    animation-delay: .25s;
}
.contactEmailBall:nth-of-type(3){
    animation-delay: .5s;
}
.contactEmailBall:nth-of-type(4){
    animation-delay: .75s;
}
.contactEmailBall:nth-of-type(5){
    animation-delay: 1s;
}
.contactEmailBall:nth-of-type(6){
    animation-delay: 1.25s;
}

@keyframes animBallContact{
    from{
        transform: scale(1);
    }
    
    20%{
        transform: scale(1);
    }
    25%{
        transform: scale(1.3);
    }
    30%{
        transform: scale(1);
    }
    to{
        transform: scale(1);
    }
}

.contactEmailSideText{
    font-size: 1.3em;
}

/*Pacman*/
.contactEmailPacman{
    position: absolute;
    right: 0;
    transform-origin: center;
    width: 200px;
    height: 200px;
    background-color: #E8EAED;
    border-radius: 50%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0% 85%, 52% 52%, 0 15%, 0 0);
}

/*LINKS*/
.contactLinksContainer{
    margin-left: 10%;
    margin-top: 3%;

}

.contactLinkItemBox{
    position: relative;
    margin-top: 10px;
}

.contactLinkItem{ 
    position: relative;
    text-decoration: none;
    display: inline-block;

}

.contactLinkItem>a{
    text-decoration: none;
    color: black;
    margin-top: 10%;
    font-size: 1.5em;
}

.contactLinkItemBall{
    position: absolute;
    left: -15px;
    top: 10px;
    display: inline-block;
    background-color: #EDBD21;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.contactLinkItem> .boxHoverBehind{
    background-color: #EDBD21;

}

.contactLinkItem:hover> .boxHoverBehind{
    width: 105%;
}

.contactLinkItem:hover ~ .contactLinkItemBall{
    background-color: #33495E;
}

/*////////// FOOTER //////////*/
.footerContainer{
    position: relative;

    margin-top: 2%;
    margin-bottom:30px;

}

.pacmanContainer{
    z-index: 2;
}

.pacmanContainer>img{
    z-index: 2;
    position: absolute;
    width: 50px;
    top: -20px;
    left: 10px;
    clip-path: polygon(0 0, 100% 0, 100% 15%, 52% 52%, 100% 85%, 100% 100%, 0 100%, 0 0);
    animation: pacmanMoveAnim 25s infinite linear, pacmanEatAnim 0.15s infinite alternate ease-in-out 2s;;
}

@keyframes pacmanEatAnim{
    from{
        z-index: 2;
        clip-path: polygon(0 0, 100% 0, 100% 15%, 52% 52%, 100% 85%, 100% 100%, 0 100%, 0 0);
    }
    to{
        z-index: 2;
        clip-path: polygon(0 0, 100% 0, 100% 52%, 52% 52%, 100% 52%, 100% 100%, 0 100%, 0 0);
    }
}

@keyframes pacmanMoveAnim{
    from{
        left:10px;
        transform: rotate(0deg);
    }
    12.5%{
        /*WAIT*/
        left:10px;
        transform: rotate(0deg);
    }
    42.5%{ 
        /* EAT */
        left: 96.5%;
        transform: rotate(0deg);
    }
    50%{
        /* WAIT */
        left: 96.5%;
        transform: rotate(0deg);
    }
    55%{
        transform: rotate(180deg);
    }
    62.5%{
        /* WAIT */
        left: 96.5%;
        transform: rotate(180deg);
    }
    92.5%{
        /* EAT */
        left: 10px;
        transform: rotate(180deg);
    }
    to{
        /* WAIT */
        left: 10px;
        transform: rotate(0deg);
    }
}

.pacmanBallsContainer{
    z-index: 1;
    display: flex;
    padding-left: 1.5em;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    /* animation: vanishBalls 15s infinite cubic-bezier(.54,.59,.86,.77); */
    animation: vanishBalls 25s infinite cubic-bezier(.41,.47,.8,.72);
}

@keyframes vanishBalls{
    from{
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%, 0 0);
    }
    5%{
        /* DISTRIBUTE */
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
    12.5%{
        /*WAIT*/
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
    42.5%{ 
        /* EAT */
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%, 100% 0);
    }
    50%{
        /* WAIT */
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%, 100% 0);
    }
    55%{
        /* DISTRIBUTE */
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
    62.5%{
        /* WAIT */
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
    92.5%{
        /* EAT */
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%, 0 0);
    }
    to{
        /* WAIT */
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%, 0 0);
    }

    
}

.pacmanBallsContainer>img{
    flex-direction: row;
    width: 14px;
    margin-left: 14px;
}

.ballHere{
    display: block;
}

.ballVanish{
    display: none;
}

/*PHONE VERSIONS*/
@media (max-width:850px){
    .pageContainer{
        overflow-x: hidden;
    }

    .headerContainer{
        margin-top: 5%;
        justify-content: space-around;
    }

    .logoContainer{
        flex: .6;
    }

    .logoPacman{
        width: 16vw;
        height: 16vw;
    }

    .headerRightSide{
        display: none;
    }

    /*BURGER MENU*/
    .burgerMenuContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40px;
        order: 2;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 5;
        transition: all .2s ease-in;
    }

    .burgerMenuBox{
        position: relative;
        width: 100%;
        height: 100%;
    }

    .burgerMenuBar{
        position: absolute;
        background-color: #33495E;
        width: 100%;
        height: 5px;
        transition: all .3s cubic-bezier(0,.42,.78,1.39);
        z-index: 5;
        transform-origin: center;
    }

    .burgerMenuBar:nth-of-type(1){
        top: 0%;
    }
    .burgerMenuBar:nth-of-type(2){
        top: 50%;
    }
    .burgerMenuBar:nth-of-type(3){
        top: 100%;
    }

    .burgerActive>.burgerMenuBox>.burgerMenuBar:nth-of-type(1){
        top: 50%;
    }
    .burgerActive>.burgerMenuBox>.burgerMenuBar:nth-of-type(3){
        top: 50%;
    }

    .burgerActive>.burgerMenuBox>.burgerMenuBar{
        background-color: white;
    }

    /*BURGER BOX*/
    
    /*lang*/
    .burgerLangContainer{
        position: absolute;
        top: 10px;
        left: 20px;
        color: white;
    }

    .selectedLang{
        color: black;
    }

    /*nav*/
    .burgerActive > .burgerMenuNavContainer{
        transform: translateX(-100vw);
    }

    .burgerMenuNavContainer{
        position: fixed;
        top: 0;
        left: 100%;
        width: 100vw;
        height: 100vh;
        background-color: #33495E;
        z-index: 4;
        display: inline-block;
        transition: transform .3s ease-in-out;
    }

    .burgerContent{
        height: 100%;
        position: relative;
    }

    .burgerNavContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 20vh;
        margin-top: 20vh;
        position: relative;
    }

    .linkBurgerContainer{
        display:flex;
        justify-content: center;
        position: relative;
    }

    .navBurgerItem{
        text-decoration: none;
        color: white;
        font-size: 1.8em;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .navBurgerItem::before{
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        transform-origin: center;
        transform: translateX(-40%);
        position: absolute;
        background: #fff;
        width: 10%;
    }

    

    /*HOME*/

    /*skills*/
    .mainSkills{
        margin-top: 10%;
    }
    .skillsListElement{
        font-size: .8em;
    }

    /*resume*/
    #homeResumeContainer{
        font-size: .65em;
        width: 100vw;
        margin-bottom: 22vh;
        margin-top: 15%;
    }

    #homeResumeContainer{
        padding: 0;
        margin-bottom: 17vh;
    }
    #resumeContainer{
        width: 90%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 5%;
    }
    #resumeP1, #resumeP2{
        width: 100%;
        margin-left: 0;
    }

    .resumeElement{
        align-self: center;
    }


    #contentContainer,.contentContainer{
        margin-top: 10%;
    }
    /*MY WORK PAGE*/
    .myWorkItem{
        flex-grow: 1;
    }

    /*PROJECT LIST*/
    
    /*title*/
    .listTitleContainer, .myWorkTitleContainer{
        width: 65%;
        margin-bottom: 10%;
    }

    .listTitle{
        font-size: .9em;
    }

    /*field*/
    .listField{
        margin-left: 0;
    }
    .listFieldTitle{
        font-size: 1.4em;
        width: 90%;
    }

    /*item*/
    .listItem{
        font-size: .8em;
        width: 90%;
    }
    .listItemSoftware{
        width: 150px;
        left: 0;
        top: auto;
        bottom: -20px;
    }

    .listItemDate{
        top: auto;
        bottom: -44px;
    }

    .listItemBall{
        top: 10px;
        left: -20px;
    }

    .listItem{
        margin-left: 5%;
        margin-top: 60px;
        transform-origin: top left;
    }
    
    .listField>.listItem:nth-of-type(1){
        margin-top: 25px;
    }

    .listField{
        margin-top: 5%;
    }

    /*PROJECT PROFILE */
    /*title*/
    .projectTitleContainer{
        width: 80%;
        padding-left: 10%;
    }
    .projectTitle{
        font-size: .8em;
    }

    /*backToList*/
    .backToList{
        width: 10%;
        top: 8px;
    }

    .ballBack{
        width: 2vw;
        height: 2vw;
    }

    /*infos*/
    .projectInfosContainer{
        width: 30%;
        min-width: 80%;
        max-width: 500%;
    }

    .projectCollabContainer{
        margin-bottom: 5%;
    }

    /*preview gallery*/
    .galleryExpandedImg{
        height: 55%;
        align-items: flex-end;
    }

    #galleryExpandedImgContent{
        height: auto;
        width: 70vw;
    }

    .gallerExpandedImgContainer{
        height: auto;
    }
    .galleryImgRowContainer{
        height: 30%;
        width: 90%;
    }
    .galleryImgRowItem{
        border: white solid 1px;
    }

    .exitGallery{
        top: -50px;
    }

    /*text*/
    #projectTextContentContainer{
        max-width: 90%;
    }

    /*bottom*/
    .projectBottomContainer{
        margin-top: 10% !important;
        margin-bottom: 6%;
    }

    /*video*/
    .projectVideoContainer>iframe{
        width: 80vw;
        height: 45vw;
    }

    /*CONTACT PAGE */
    .contactEmailPacman{
        display: none;
    }

    .contactContainer{
        height:73vh;
    }

    /*email*/
    .contactEmail{
        font-size: 2.3vw;
    }
    .contactEmailSideText{
        font-size: 4.7vw;
    }

    /*MY WORK PAGE*/
    .myWorkContent{
        max-width: 95%;
    }


}